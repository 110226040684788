import Component from '../core/Component';
import WOW from 'wow.js';


export default class Animation extends Component {
    prepare() {
        const wow = new WOW(
          {
            offset: 150
        });

        wow.init();
    }
}