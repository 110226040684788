import Component from '../core/Component';
import scrollToElement from '../services/Viewport/scrollToElement';

export default class ScrollAnchors extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            sections: [],
            anchors: []
        }

        this.current = ''
        this.remainer = 1000
    }

    prepare() {
        this.ref.anchors.forEach(anchor => {
            anchor.addEventListener('click', ::this.handleClick);
        });

        window.addEventListener('scroll', ::this.handleScroll);

        this.ref.sections.forEach(section => {
            if (this.getDifference(section) < this.remainer) {
                this.current = section
                this.remainer = this.getDifference(section)
            }
        });
    }

    handleClick(event) {
        const id = event.target.closest('.SidebarNav-link').getAttribute('href').replace('#', '');

        const target = document.getElementById(id);

        if (target) {
            event.preventDefault();
            scrollToElement(target);
        }
    }

    handleScroll() {
        document.querySelector('[href="#' + this.current.getAttribute('id') + '"]').classList.remove('is-active')

        this.remainer = 1000

        this.ref.sections.forEach(section => {
            if (this.getDifference(section) < this.remainer) {
                this.current = section
                this.remainer = this.getDifference(section)
            }
        });

        document.querySelector('[href="#' + this.current.getAttribute('id') + '"]').classList.add('is-active')
    }

    getDifference(section) {
        return Math.abs(section.getBoundingClientRect().top - 100) + section.getBoundingClientRect().height / 2
    }
}



