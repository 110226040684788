import Component from '../core/Component';
import Stickyfill from 'stickyfilljs'

export default class Sticky extends Component {
    constructor(element) {
        super(element);
    }

    prepare() {
        Stickyfill.addOne(this.element);
    }
}
