import Component from '../core/Component';
import { toggleClass } from '../utils/dom';


export default class Filter extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            select: null,
            switch: null,
            filter: null,
            items: []
        }
    }

    prepare() {
        this.handleChange()
        this.ref.select.addEventListener('change',:: this.handleChange)
        this.ref.switch.addEventListener('change',:: this.handleSwitch)
    }

    handleChange(e) {
        const selectedCategory = this.ref.select.value

        if (selectedCategory == 0) {
            this.ref.filter.classList.add('is-hidden')
            this.ref.switch.checked = false
            this.element.classList.remove('is-hidden')
        } else {
            this.ref.filter.classList.remove('is-hidden')
            this.ref.switch.checked = true
            toggleClass(this.element,"is-hidden", this.ref.switch.checked)
        }

        this.ref.items.forEach(item => toggleClass(item, "is-active", item.dataset.category == selectedCategory));
    }

    handleSwitch() {
        toggleClass(this.element,"is-hidden", this.ref.switch.checked)
    }
}