import Component from '../core/Component';

export default class NavOpener extends Component {
    prepare() {
        this.element.addEventListener('click',::this.handleClick)
    }

    handleClick() {
        document.documentElement.classList.toggle('nav-is-open')
    }
}